$jumbo: #7F7F83;
$mercury: #E6E6E6;
$shark: #1B1A20;
$link-blue: #5E5EFF;
$white: #ffffff;
$wild-sand: #e5e5e5;

//blues
$malibu: #00D2C4;
$malibu-light: #00857C;

//greens
$limeade: #00F79D;
$limeade-light: #008856;

//oranges
$clementine: #FF453D;
$clementine-light: #ED0A00;

$meta: #BC55FA;
$meta-light: #AF33F9;

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@400;600;700&display=swap');

* {
	box-sizing: border-box; }

body {
	background: #000000;
	background: -webkit-linear-gradient(to right, #222222, #1B1A20);
	background: linear-gradient(to right, #222222, #1B1A20);
	color: #shark;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	height: 100%;
	overflow-x: hidden;
	width: 100%; }


h1, h2, h3, h4 {
	font-family: Bitter, serif;
	letter-spacing: 0.0125em;
	line-height: 1.1;
	text-rendering: optimizeLegibility; }

h1 {
	color: $white;
	font-size: 5em;
	font-weight: 700;
	margin: 0;
	margin-bottom: 0.25em;
	position: relative; }

h2 {
	color: $white;
	font-size: 3em;
	margin: 0 0 0.5em; }

p {
	color: #444;
	line-height: 1.5;
	margin: 0;
	margin-bottom: 1em; }

main {
	margin: 0 0 0 10%;
	//max-width: 72em
	padding: 2em 0 2em; }

.card-row {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: flex-start; }

.card {
	background: white;
	border-radius: 0.5rem;
	border-bottom: 4px ridge;
	color: $shark;
	//max-width: calc(17% - 0.75rem)
	max-width: 18em;
	text-decoration: none;
	width: 17vw;
	.body {
		display: grid;
		grid-template-rows: 1fr 4fr 1fr;
		height: 20em;
		padding: 2em 2em 1em; }
	img {
		border-radius: 8px 8px 0 0;
		max-width: 100%; }
	h2 {
		color: $shark;
		font-size: 1.5em;
		margin: 0;
		margin-bottom: 0.5rem; }
	p {
		display: -webkit-box;
		-webkit-line-clamp: 7;
		-webkit-box-orient: vertical;
		max-height: 10.5em;
		margin: 0;
		overflow: hidden; }
	&:hover {
		h2 {
			color: $link-blue; } } }

.card[data-type="Lenses"] {
	border-color: $malibu;
	.tag {
		color: $malibu-light; } }

.card[data-type="Categorizing"] {
	border-color: $clementine;
	.tag {
		color: $clementine-light; } }

.card[data-type="Mapping"] {
	border-color: $limeade;
	.tag {
		color: $limeade-light; } }

.card[data-type="Meta-Framework"] {
	border-color: $meta;
	.tag {
		color: $meta-light; } }

.blue {
	color: $malibu; }

.green {
	color: $limeade; }

.orange {
	color: $clementine; }

.meta {
	color: $meta; }

.tag {
	align-self: flex-end;
	font-weight: 700;
	text-transform: uppercase; }

header {
	align-items: flex-end;
	border-bottom: 1px solid $white;
	color: $white;
	display: flex;
	gap: 3em;
	margin: 4em 0;
	padding: 2em 0;
	padding-right: 2em;
	p {
		color: $white;
		font-size: 1.25em;
		max-width: 40em; }
	img {
		margin-bottom: 1.5em;
		max-height: 2em; } }

footer {
	margin: 3em 0;
	text-align: center; }

footer p, footer a {
	color: $white; }


//Card deck
.deck {
	display: flex;
	margin-bottom: 4em; }

.card-row {
	flex-wrap: wrap;
	gap: 1rem;
	//overflow-x: visible
	padding-bottom: 1em;
	padding-right: 2vw;
	position: relative; }

.fade {
	background: linear-gradient(90deg, rgba(27, 26, 32, 0) 0%, rgba(27, 26, 32,1) 85%, rgba(27, 26, 32,1) 100%);
	bottom: 0;
	content: "";
	left: 85vw;
	position: fixed;
	right: 1.5em;
	top: 0;
	z-index: 1; }


.card {
	margin: 0;
	scroll-snap-align: start; }

.card:not(:first-child) {
 }	//margin-left: -1rem

.search {
	margin-bottom: 3em; }

.search h2, .filters h2 {
	font-size: 1rem; }


.toggle-btn {
	background: none;
	border: 1.5px solid $white;
	border-radius: 0.25em;
	color: $white;
	font-size: 1em;
	margin: 0.5em;
	padding: 0.5em 1em;
	&:hover {
		background: lighten($shark, 20%);
		cursor: pointer; }
	&:focus {
		outline: 2px solid $link-blue;
		outline-offset: 2px; }
	&:first-of-type {
		margin-left: 0; }
	&:last-of-type {
		border: 0 none; } }

.toggle-btn[aria-pressed="true"] {
	background: $link-blue;
	border-color: $link-blue;
	color: $white; }

.search-input {
	background: lighten($shark, 10%);
	border: 0 none;
	border-radius: 0.25em;
	color: $wild-sand;
	font-size: 1.1em;
	font-family: Roboto, sans-serif;
	max-width: 30em;
	padding: 0.5em 1em;
	width: 100%;
	&:hover {
		background: lighten($shark, 20%); }
	&:focus {
		outline-color: $link-blue;
		outline-offset: 2px; } }



@media (max-width: 1600px) {
	.card {
		max-width: calc(20vw - 0.75rem);
		width: 20vw; }

	h1 {
		font-size: 5vw; } }

@media(min-width: 993px) {
	.fade {
		display: none; } }

@media (max-width: 992px) {
	.card {
		max-width: none;
		width: 33.33vw; }

	main {
		margin: 0 0 0 3em;
		padding: 2em 0 2em; }

	.card-row {
		flex-wrap: nowrap; }

	.deck {
		scrollbar-color: #666 #201c29;
		scrollbar-gutter: always;
		padding: 0;
		margin: 0;
		display: flex;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch; }

	header {
		align-items: flex-start;
		flex-direction: column;
		justify-content: flex-start;
		margin: 0 0 2em;
		padding: 2em 3em 2em 0; }

	.fade {
		right: 0; } }

@media (max-width: 640px) {
	h1 {
		font-size: 2em; }

	h2 {
		font-size: 1.5em; }

	main {
		margin-left: 0;
		padding: 2em; }

	.card {
		min-width: 15em; }

	header {
		padding-right: 0;
		p {
			font-size: 1em; } } }

